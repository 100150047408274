.centeredLoader{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}
.LoaderContainer{
    z-index: 1000;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
