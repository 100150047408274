footer{
    padding: 10px;
	box-shadow: -1px -1px 10px gray;

    .footerOptions{
        display: flex;
        justify-content: space-evenly;
        // align-items: center;
        flex-wrap: wrap;
        div{
            margin: 10px;
        }
    }
    .footerBottom{
        text-align: center;
        padding: 5px 20px;
    }
}


.dark footer{
    background: #162a40;
}