header{
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	box-shadow: 1px 1px 10px 1px gray;
	padding: 10px;
	.DesktopNav{
		display: flex;
		justify-content: space-between;
		align-items: center;
		a{
			padding: 10px 30px;
		}
		button{
			padding: 10px 20px;
			background: midnightblue;
			color: white;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 5px;
			svg{
				margin: 0 5px ;
			}
		}
		a:hover,button:hover{
			background: blue;
			color: white;
		}
	}
}



.MobileNav{
	display: none;
}

@media screen and (max-width: 768px) {
	.MobileNav{
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		z-index: 100000;
		background-color: mintcream;
		>svg{
			position: absolute;
			top: 10px;
			right: 10px;
			font-size: 35px;
		}
		a{
			width: 100%;
			padding: 30px;
			text-align: center;
		}
		button{
			padding: 10px 20px;
			background: midnightblue;
			color: white;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 5px;
			svg{
				margin: 0 5px ;
			}
		}
		a:hover,button:hover{
			background: blue;
		}
	}
}


.dark header{
	background: #162a40;
	button{
		background: blue;
		color: white;
	}
	button:hover{
		background: midnightblue;
	}
	.MobileNav{
		background: #162a40;
	}
}