.display_none{
    display: none !important;
}
.display_flex{
    display: flex !important;
}
.coral_color{
    color: coral;
}
.darkblue_color{
    color: #130f40;
}
.gray_color{
    color: gray;
}
.blue_color{
    color: blue;
}
.red_color{
    color: red;
}
.green_color{
    color: green;
}
.black_color{
    color: black;
}
.text_center{
    text-align: center;
}
.align_center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.small_icon{
    width: 50px;
    max-height: 50px;
}
.main_heading_1{
    text-align: center;
    font-size: 40px;
    font-weight: bolder;
    text-shadow: 5px 5px 5px rgb(0, 19, 128);
    margin-top: 20px;
}
.position_fixed{
    position: fixed;
    top: 0;
    left: 0;
}






.notificationContainer{
    background: rgba(128, 128, 128, 0.363);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 11;
}




#animation{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(128, 128, 128, 0.663);
    .sun{
        position: absolute;
        bottom: 0;
        // right: ;
        background-color: yellow;
        height: 10vh;
        width: 10vh;
        border-radius: 50%;
        animation: sun 0.3s linear 1;
        transform: translate(0,50%);
        background:rgb(255, 238, 0);
        box-shadow: 0 0 10px rgb(255, 230, 0),
                      0 0 60px rgb(255, 208, 0),
                      0 0 200px yellow,
                      inset 0 0 80px yellow;
    }
    .moon{
        height: 40vw;
        width: 40vw;
        border-radius: 50%;
        background-color: white;
        box-shadow: 0 0 100px 20px rgb(255, 255, 255) !important;
        animation: moon 0.3s linear 1;
        position: absolute;
        left: 0;
        // top: 40%;
        z-index: 200;
    }
}


@keyframes sun{
    0%{
        height: 10vh;
        width: 10vh;
    }
    50%{
        height: 300vh;
        width: 300vh;    
    }
    100%{
        height: 500vh;
        width: 500vh;
    }
}

@keyframes moon{
    0%{
        left: 0%;
    }
    50%{
        left: 50%;
    }
    100%{
        left: 100%;
    }
}





